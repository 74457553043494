import React from 'react'
import SEO from '../components_v2/seo'
import Header from '../components_v2/Header'
import Contact from '../components_v2/Contact'
import Portfolio from '../components_v2/Portfolio'
import Service from '../components_v2/Services'
import LayoutV2 from '../components_v2/Layout'
import './index.css'

const IndexPage = () => (
  <LayoutV2>
    <SEO title="Home" />
    <Header />
    <Service />
    <Portfolio />
    <Contact />
  </LayoutV2>
)

export default IndexPage
