import React from 'react'

const MapMarker = () => (
  <svg
    viewBox="0 0 11 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.70312 14.4766C4.26562 13.8385 3.76432 13.1185 3.19922 12.3164C2.65234 11.5143 2.26042 10.9492 2.02344 10.6211C1.78646 10.293 1.51302 9.89193 1.20312 9.41797C0.893229 8.92578 0.683594 8.57943 0.574219 8.37891C0.483073 8.17839 0.373698 7.92318 0.246094 7.61328C0.136719 7.30339 0.0638021 7.03906 0.0273438 6.82031C0.00911458 6.58333 0 6.3099 0 6C0 4.54167 0.510417 3.30208 1.53125 2.28125C2.55208 1.26042 3.79167 0.75 5.25 0.75C6.70833 0.75 7.94792 1.26042 8.96875 2.28125C9.98958 3.30208 10.5 4.54167 10.5 6C10.5 6.3099 10.4818 6.58333 10.4453 6.82031C10.4271 7.03906 10.3542 7.30339 10.2266 7.61328C10.1172 7.92318 10.0078 8.17839 9.89844 8.37891C9.80729 8.57943 9.60677 8.92578 9.29688 9.41797C8.98698 9.89193 8.71354 10.293 8.47656 10.6211C8.23958 10.9492 7.83854 11.5143 7.27344 12.3164C6.72656 13.1185 6.23438 13.8385 5.79688 14.4766C5.66927 14.6589 5.48698 14.75 5.25 14.75C5.01302 14.75 4.83073 14.6589 4.70312 14.4766ZM3.69141 7.55859C4.12891 7.97786 4.64844 8.1875 5.25 8.1875C5.85156 8.1875 6.36198 7.97786 6.78125 7.55859C7.21875 7.12109 7.4375 6.60156 7.4375 6C7.4375 5.39844 7.21875 4.88802 6.78125 4.46875C6.36198 4.03125 5.85156 3.8125 5.25 3.8125C4.64844 3.8125 4.12891 4.03125 3.69141 4.46875C3.27214 4.88802 3.0625 5.39844 3.0625 6C3.0625 6.60156 3.27214 7.12109 3.69141 7.55859Z" />
  </svg>
)

export default MapMarker
