import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

export const Wrapper = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: ${({ theme: { spaces } }) => spaces.large};
  padding-top: 50px;
`
export const Container = styled.div``
export const PhraseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const PhraseImg = styled.img`
  height: ${({ theme: { fontSizes } }) => fontSizes.larger};
  width: ${({ theme: { fontSizes } }) => fontSizes.larger};
  margin-right: ${({ theme: { spaces } }) => spaces.larger};
`
export const PhraseParagraph = styled.h1`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primary};
  margin: ${({ theme: { spaces } }) => spaces.medium};
  @media (min-width: 600px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  }
  @media (min-width: 1280px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.larger};
  }
`

export const Phrase = ({ src, text }) => (
  <PhraseContainer>
    <PhraseImg src={src} alt={text} />
    <PhraseParagraph>{text}</PhraseParagraph>
  </PhraseContainer>
)

Phrase.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
