import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

export const Wrapper = styled.div`
  margin-top: 3rem;
`
export const SlideWrapper = styled.div`
  :focus {
    outline: none;
  }
`
export const Card = styled.div`
  display: flex;
  background: white;
  margin: 2rem auto;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  max-width: 700px;
`
export const ImageContainer = styled.div`
${props => props.disbaleWidth} {
  width: ${props => (props.active ? '300px' : '120px')};

}
  max-width:300px;
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
`
export const DetailsContainer = styled.div`
  flex: 1;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
`
export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
  color: #535353;
`
export const Description = styled.p``
export const TechsContainer = styled.div`
  display: flex;
  margin-bottom: 0.7rem;
  flex-wrap: wrap;
`
export const TechsText = styled.p`
  font-weight: bold;
  color: #535353;
  margin: 0;
`
export const Techs = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
`
export const TechsItem = styled.li`
  list-style-type: none;
  margin: 0 0.5rem;
  font-size: 0.85rem;
`
export const CardLink = styled.a`
  padding: 14px 18px;
  background: #2ab640;
  color: white;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-around;

`

export const Project = ({ img, title, techs, description, href, index }) => (
  <Card>
  <ImageContainer active={index === 0} disbaleWidth={index === 1}>
      <ProjectImage src={img} alt={title} />
    </ImageContainer>
    <DetailsContainer>
      <Title>{title}</Title>
      <TechsContainer>
        <TechsText>TECHS:</TechsText>
        <Techs>
          {techs.map(item => (
            <TechsItem key={item}>{item}</TechsItem>
          ))}
        </Techs>
      </TechsContainer>
      <Description>{description}</Description>
      {href ? (
        <CardLink target="_blank" rel="noopener noreferrer" href={href}>
       <span> Visit</span> <span> {href.split("//")[1]} </span>    <span>↗️</span>
        </CardLink>
      ) : (
        <CardLink target="_blank" rel="noopener noreferrer" href="#" aria-label={title}>
          Coming soon
        </CardLink>
      )}
    </DetailsContainer>
  </Card>
)
Project.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  techs: PropTypes.instanceOf(Array).isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string,
}
Project.defaultProps = {
  href: null,
}

export const NextArrowStyled = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 3rem;
  background: transparent;
  outline: none;
  border: none;
  color: ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;
  display: none;
  @media (min-width: 900px) {
    display: inline-block;
  }
`
export const PrevArrowStyled = styled.button`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 3rem;
  background: transparent;
  outline: none;
  border: none;
  color: ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;
  z-index: 10;
  display: none;
  @media (min-width: 900px) {
    display: inline-block;
  }
`

/* eslint-disable react/prop-types */
export const NextArrow = ({ onClick }) => <NextArrowStyled onClick={onClick}>&gt;</NextArrowStyled>
/* eslint-disable react/prop-types */
export const PrevArrow = ({ onClick }) => <PrevArrowStyled onClick={onClick}>&lt;</PrevArrowStyled>
