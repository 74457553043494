import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Wrapper, Phrase, Container } from '../styles/header'
import { ButtonV2 } from './Buttons'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      allIntroTitlesJson {
        edges {
          node {
            title
            alt
            img {
              publicURL
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Container>
        {data.allIntroTitlesJson.edges.map(edge => (
          <Phrase key={edge.node.title} src={edge.node.img.publicURL} text={edge.node.title} />
        ))}
      </Container>
      <ButtonV2
        variant="primary"
        onClick={() => {
          document.getElementById('contact').scrollIntoView({
            behavior: 'smooth',
          })
        }}
      >
        CONTACT
      </ButtonV2>
    </Wrapper>
  )
}

export default Header
