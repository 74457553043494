import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Wrapper, CardsContainer } from '../styles/services'
import { LargeV2, MediumV2, SmallV2 } from './Typography'
import { ServiceCardV2, ServiceCardImageContainerV2, CardContentContainerV2, CardIconV2 } from './Cards'

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      servicesJson {
        services {
          name
          summary
          image {
            publicURL
          }
        }
      }
    }
  `)
  const { services } = data.servicesJson
  return (
    <Wrapper id="services">
      <LargeV2 align="center" color="gray">
        Our Services
      </LargeV2>
      <CardsContainer>
        {services.map(({ name, summary, image }, i) => (
          <ServiceCardV2 key={name.concat(i)}>
            <ServiceCardImageContainerV2>
              <CardIconV2 src={image.publicURL} alt={name} />
            </ServiceCardImageContainerV2>
            <CardContentContainerV2>
              <MediumV2 bold color="black" align="center">
                {name}
              </MediumV2>
              <SmallV2 color="gray" align="center">
                {summary}
              </SmallV2>
            </CardContentContainerV2>
          </ServiceCardV2>
        ))}
      </CardsContainer>
    </Wrapper>
  )
}

export default Services
