import styled from 'styled-components'

export const InputGroupV2 = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme: { spaces } }) => spaces.small};
`
export const InputLabelV2 = styled.label`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  color: ${({ theme: { colors } }) => colors.gray};
  margin-bottom: ${({ theme: { spaces } }) => spaces.small};
  color: ${({ theme: { colors } }) => colors.gray2};
`
export const InputFieldV2 = styled.input`
  padding: 0.4rem 0.6rem;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  border-radius: 0.3rem;
  color: ${({ theme: { colors } }) => colors.primary};
  font-weight: 500;
  :focus {
    outline: none;
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    box-shadow: 0px 0px 2px ${({ theme: { colors } }) => colors.primary};
  }
  ::placeholder {
    color: ${({ theme: { colors } }) => colors.primary};
  }
`

export const SelectFieldV2 = styled.select`
  padding: 0.4rem 0.6rem;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  border-radius: 0.3rem;
  color: ${({ theme: { colors } }) => colors.primary};
  font-weight: 500;
  background: white;
  :focus {
    outline: none;
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    box-shadow: 0px 0px 2px ${({ theme: { colors } }) => colors.primary};
  }
  ::placeholder {
    color: ${({ theme: { colors } }) => colors.primary};
  }
`
export const SelectOptionV2 = styled.option``

export const TextAreaV2 = styled.textarea`
  padding: 0.4rem 0.6rem;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  border-radius: 0.3rem;
  color: ${({ theme: { colors } }) => colors.primary};
  font-weight: 500;
  background: white;
  :focus {
    outline: none;
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    box-shadow: 0px 0px 2px ${({ theme: { colors } }) => colors.primary};
  }
  ::placeholder {
    color: ${({ theme: { colors } }) => colors.primary};
  }
  resize: none;
  height: 4em;
`
