import styled from 'styled-components'

export const ServiceCardV2 = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  padding: ${({ theme: { spaces } }) => spaces.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 900px) {
    max-width: 350px;
  }
`
export const ServiceCardImageContainerV2 = styled.div`
  margin: 0;
`
export const CardContentContainerV2 = styled.div`
  margin: 0;
`
export const CardIconV2 = styled.img`
  width: 50px;
  height: 50px;
  margin: 0;
`

export const TeamCard = styled.div`
  color: #535353;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 2rem;
  height: 300px;
  @media (max-width: 600px) {
    margin: 1rem 0.5rem;
    > p {
      font-size: 1rem;
    }
  }
`
