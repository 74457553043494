/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable no-shadow */
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Wrapper, Project, SlideWrapper, NextArrow, PrevArrow } from '../styles/portfolio'
import { LargeV2 } from './Typography'

const Portfolio = ({ refs }) => {
  const data = useStaticQuery(graphql`
    query {
      allPortfolioJson {
        edges {
          node {
            description
            link
            techs
            title
            logo {
              childImageSharp {
                fluid {
                  src
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  const projects = data.allPortfolioJson.edges

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <Wrapper ref={refs} id="portfolio">
      <LargeV2 align="center" color="gray">
        Our Portfolio
      </LargeV2>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...settings}
      >
        {projects.map((item, index) => (
          <SlideWrapper key={index}>
            <Project
              img={item.node.logo.childImageSharp.fluid.src}
              title={item.node.title}
              techs={item.node.techs}
              description={item.node.description}
              href={item.node.link}
              index={index}
            />
          </SlideWrapper>
        ))}
      </div>
    </Wrapper>
  )
}
Portfolio.propTypes = {
  refs: PropTypes.shape({ current: PropTypes.elementType }),
}
Portfolio.defaultProps = {
  refs: null,
}
Portfolio.defaultProps = {
  refs: null,
}

export default Portfolio
