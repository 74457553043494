import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ theme: { spaces } }) => `0 ${spaces.large}`};
`
export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-items: center;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
