import React from 'react'

const CallSVG = () => (
  <svg
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.5078 1.43359C13.8542 1.50651 14.0273 1.71615 14.0273 2.0625C14.0273 5.5625 12.7878 8.55208 10.3086 11.0312C7.82943 13.5104 4.83984 14.75 1.33984 14.75C0.99349 14.75 0.783854 14.5768 0.710938 14.2305L0.0546875 11.3867C-0.0364583 11.0404 0.0911458 10.7943 0.4375 10.6484L3.5 9.33594C3.79167 9.20833 4.04688 9.27214 4.26562 9.52734L5.60547 11.168C6.68099 10.6758 7.62891 10.0104 8.44922 9.17188C9.28776 8.33333 9.95312 7.38542 10.4453 6.32812L8.80469 4.96094C8.54948 4.76042 8.48568 4.50521 8.61328 4.19531L9.92578 1.13281C10.0716 0.804688 10.3177 0.686198 10.6641 0.777344L13.5078 1.43359Z" />
  </svg>
)

export default CallSVG
