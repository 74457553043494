import React, { useState } from 'react'
import { ButtonV2 } from './Buttons'
import { formHandler } from '../utils'
import { InputGroupV2, InputLabelV2, InputFieldV2, SelectFieldV2, SelectOptionV2, TextAreaV2 } from './InputFields'
import { LargeV2, SmallV2 } from './Typography'
import MapMarker from '../static/MapMarker.svg'
import EmailSVG from '../static/EmailIcon.svg'
import CallSVG from '../static/CallIcon.svg'

import { Wrapper, InfoSection, Form, ContactInfoContainer, NonSocialContactContainer, SingleContactInfo } from '../styles/contact'

const Contact = () => {
  const [formState, setFormState] = useState('Send')
  const [name, setName] = useState('')
  const [interest, setInterest] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const inputsSetter = [setName, setInterest, setEmail, setMessage]

  const HandleSubmit = async e => {
    e.preventDefault()
    if (formState !== 'Send') return
    setFormState('Sending...')
    const success = await formHandler({
      source: 'form',
      name,
      interest,
      email,
      message,
    })
    if (success) {
      setFormState('Sent!')
      inputsSetter.forEach(setInput => setInput(''))
      setTimeout(() => {
        setFormState('Send')
      }, 2000)
      return
    }
    setFormState('Failed!, try refreshing the page! ')
  }
  return (
    <Wrapper id="contact">
      <InfoSection>
        <LargeV2 color="gray">Let&apos;s Talk</LargeV2>
        <SmallV2 color="gray">
          Come prepared with your problems, challenges, and vision. We will do whatever it takes to transform your vision into reality and
          your problems into fiction.
        </SmallV2>
        <SmallV2 color="gray">
          We acknowledge the significance of our client’s requirements and believe in customer-centricity. We strive to deliver the best
          possible solutions
        </SmallV2>
        <ContactInfoContainer>
          <NonSocialContactContainer>
            <SingleContactInfo target="_blank" rel="noreferrer" href="https://g.page/zaatdev?share">
              <MapMarker />
              Sepapaja 6, Tallinn 15551, Estonia
            </SingleContactInfo>
            <SingleContactInfo target="_blank" rel="noopener noreferrer" href="mailto:info@zaat.dev">
              <EmailSVG />
              info@zaat.dev
            </SingleContactInfo>
            <SingleContactInfo target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=972528072551">
              <CallSVG />
              whatsapp (972) 52 807 2551
            </SingleContactInfo>
          </NonSocialContactContainer>
        </ContactInfoContainer>
      </InfoSection>
      <Form onSubmit={HandleSubmit}>
        <InputGroupV2>
          <InputLabelV2 htmlFor="name">Name</InputLabelV2>
          <InputFieldV2
            required
            name="name"
            id="name"
            placeholder="name"
            autoComplete="off"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </InputGroupV2>
        <InputGroupV2>
          <InputLabelV2 htmlFor="email">E-mail</InputLabelV2>
          <InputFieldV2
            required
            name="email"
            id="email"
            placeholder="email"
            type="email"
            autoComplete="off"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </InputGroupV2>
        <InputGroupV2>
          <InputLabelV2 htmlFor="interest">Interest</InputLabelV2>
          <SelectFieldV2
            required
            name="interest"
            id="interest"
            borderColor="#54CF61"
            autoComplete="off"
            value={interest}
            onChange={e => setInterest(e.target.value)}
          >
            <SelectOptionV2 hidden value="">
              What can we help you with?
            </SelectOptionV2>
            <SelectOptionV2 value="webapp">Building a Web App|Site</SelectOptionV2>
            <SelectOptionV2 value="automation-ai">Automation|AI solution</SelectOptionV2>
            <SelectOptionV2 value="inquiry">General inquiry</SelectOptionV2>
            <SelectOptionV2 value="consultation">Consultation</SelectOptionV2>
          </SelectFieldV2>
        </InputGroupV2>
        <InputGroupV2>
          <InputLabelV2 htmlFor="message">Message</InputLabelV2>
          <TextAreaV2
            name="message"
            id="message"
            placeholder="message (Optional)"
            borderColor="#54CF61"
            autoComplete="off"
            rows="3"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </InputGroupV2>
        <InputGroupV2>
          <ButtonV2 type="submit">{formState}</ButtonV2>
        </InputGroupV2>
      </Form>
    </Wrapper>
  )
}

export default Contact
