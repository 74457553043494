import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: ${({ theme: { spaces } }) => spaces.large};
  @media (min-width: 900px) {
    display: flex;
  }
`
export const InfoSection = styled.div`
  > p {
    max-width: 60ch;
    margin: ${({ theme: { spaces } }) => spaces.large} 0;
  }
  @media (min-width: 900px) {
    flex: 2;
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spaces } }) => spaces.small};
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (min-width: 900px) {
    flex: 1;
    margin: ${({ theme: { spaces } }) => spaces.large};
  }
`

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`
export const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  @media (min-width: 1024px) {
    flex: 1;
    margin: 0;
  }
`
export const SocialIconsContainer = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin: 0 1rem;
  }
  padding-bottom: 0.6rem;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.primary};
`
export const NonSocialContactContainer = styled.div`
  @media (min-width: 1024px) {
    flex: 1;
  }
`
export const SingleContactInfo = styled.a`
  display: flex;
  font-size: 0.75rem;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.gray};
  transition: 0.3s;
  align-items: center;
  padding: 0.6rem 0;

  > svg {
    margin-right: 0.5rem;
    fill: ${({ theme: { colors } }) => colors.gray};
    min-width: 18px;
    width: 0.75rem;
  }

  :hover {
    color: ${({ theme: { colors } }) => colors.primary};
    > svg {
      fill: ${({ theme: { colors } }) => colors.primary};
    }
  }
`
export const SocialLink = styled.a`
  margin: 0 0.3rem;
  > svg {
    height: 25px;
    fill: ${({ theme: { colors } }) => colors.gray2};
    transition: fill 0.4s;
    :hover {
      fill: ${({ theme: { colors } }) => colors.black};
    }
  }
`
export const SocialBottomContainer = styled.div`
  > a {
    display: flex;
    font-size: 0.75rem;
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.gray};
    transition: 0.3s;
    align-items: center;
    > svg {
      margin-right: 0.5rem;
      fill: ${({ theme: { colors } }) => colors.gray2};
    }

    :hover {
      color: ${({ theme: { colors } }) => colors.primary};
      > svg {
        fill: ${({ theme: { colors } }) => colors.primary};
      }
    }
    @media (min-width: 768px) {
      padding: 0.6rem 0;
    }
  }
`

export const SocialText = styled.p`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.gray2};
`
